<div class="row">
    <div class="bg-login col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12">
        <div class="row justify-content-center align-items-center" style="min-height: 100vh;">
            <div class="col ps-5">
                <div class="row">
                    <div class="col-auto">
                        <img style="height:1.5in;" class="img-fluid" src="../../assets/images/ucheck_4x.png">
                    </div>
                    
                </div>
                <div class="row justify-content-start px-5">
                    <div class="col-auto text-start">
                        <p class="text-white font-custom1">
                            Freedom From<br/>
                            Fraud With<br/>
                            Comprehensive<br/>
                            AML Checks<br/>
                        </p>
                    </div>
                </div>

                <div class="row px-5 mt-custom">
                    <div class="col-auto text-start text-white fw-bold font-custom2">
                        A PRODUCT OF 
                    </div>
                    <div class="col-auto text-start">
                        <a href="https://unawa.asia/" target="_blank">
                            <img style="height:0.3in;" class="img-fluid" src="../../assets/images/unawa_logo_white.svg">
                        </a>
                    </div>
                    
                </div>
                <div class="row px-5">
                    <div class="col-auto text-start d-flex justify-content-center text-white fs-6">
                        &#169;2024. All rights reserved. &nbsp;
                        <a href="https://unawa.asia/terms-conditions" target="_blank" class="text-white">Term & Conditions</a> &nbsp;|&nbsp; 
                        <a href="https://unawa.asia/privacy-policy" target="_blank" class="text-white">Privacy Policy</a>
                    </div>
                    
                </div>
            </div>
        </div>
                    
                


           

    
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 ">
           
                <div class="row justify-content-center align-items-center" style="background-color:#ffffff;min-height: 100vh;">
                    <div class="col-xxl-9 col-xl-9 col-auto ">                       
                        <div class="card px-2 border-0 ">
                            <div class="card-body ">
                                <h1 class="text-center fw-bolder">Sign in to begin</h1>
                                <p class="text-center fs-6 mt-5">Enter your email and password</p>
                                <form class="mb-3" [formGroup]="form">
                                    <div class="mb-3 mt-5">
                                        <label for="email" class="form-label "><small class="fw-semibold font-12">Email Address</small></label>
                                        <div class="input-group">
                                            <input placeholder="Enter your email address" formControlName="email" type="email" class="form-control {{form.get('email').touched && form.get('email').invalid? 'is-invalid' : ''}}" id="email">
                                            
                                        </div>
        
                                        <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['required']">
                                            {{field_label['email']}} is required
                                        </small>
                                        <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['pattern']">
                                            {{field_label['email']}} format is invalid
                                        </small>
        
                                        <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['user_not_found']">
                                            The email you entered did not match our records. Please double-check and try again.
                                        </small>
        
                                    </div>
                                    <div class="mb-3 mt-4">
                                        <label for="password" class="form-label"><small class="fw-semibold font-12">Password</small></label>
                                        
                                        
                                        
                                        <div class="input-group">
                                            <input style="border-right:none !important;" type="{{show_password ? 'text' : 'password'}}" class="form-control {{form.get('password').touched && form.get('password').invalid? 'is-invalid' : ''}}" formControlName="password">
                                            <img [ngStyle]="{'border-color' : form.get('password').touched && form.get('password').invalid? '#dc3545' : ''}" class="input-group-text bg-transparent" (click)="s_password('password')" src="{{show_password? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}">
                                           
                                        </div>
        
        
                                        <small class="text-danger font-12" *ngIf="form.get('password').touched && form.get('password').invalid && form.controls['password'].errors?.['required']">
                                            {{field_label['password']}} is required
                                        </small>
        
                                        <small class="text-danger font-12" *ngIf="form.get('password').touched && form.get('password').invalid && form.controls['password'].errors?.['password_incorrect']">
                                            Incorrect password. Please try again.
                                        </small>
                                        
                                    </div>
                                    <div class="row justify-content-end align-items-end mt-4">
                                        <div class="col">
                                            
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" formControlName="remember_me">
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        <small class="font-12">Remember Me</small>
                                                    </label>
                                                </div>
                                           
                                        </div>
        
                                        <div class="col-auto">
                                            <a [routerLink]="['/forgot-password']" class="nav-link">
                                                <small class="text-light-green fw-bold font-12">Forgot password</small>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </form>
        
                                <div class="d-grid gap-2 mb-3 mt-5">
                                    <button class="btn btn-login text-white" type="button" (click)="login()">Sign in</button>
                                </div>

                                <div class="d-grid gap-2 mb-3 mt-5">
                                    <button #otp_button class="d-none" data-bs-toggle="modal" data-bs-target="#otp_modal">OTP</button>
                                </div>
        
                                <div class="row justify-content-center d-none">
                                    <div class="col-auto">
                                        <small class="font-12">Don't have an account yet? 
                                            <a href="#" style="text-decoration: none;" class="text-light-green fw-bold">Sign up here</a>
                                        </small>
                                    </div>
                                </div>


                                
        
                            </div>
                        </div>
                        
                    </div>
        
                    <!-- <div class="row justify-content-center align-items-end">
                        <div class="col-auto" >
                            <small class="text-white fw-bold font-14" style="font-family: 'Lato', sans-serif !important;">
                                <a href="https://unawa.asia/terms-conditions" target="_blank" class="text-white">Terms & Condition</a> | <a href="https://unawa.asia/privacy-policy" target="_blank" class="text-white">Privacy Policy</a>
                            </small><br/>
                            <small class="text-white font-14" style="font-family: 'Lato', sans-serif !important;">
                                &copy; {{year_today}} <a target="_blank" href="https://unawa.asia/" class="text-white"><span class="fw-bold">UNAWA.</span></a> All rights reserved
                            </small>
                        </div>
                        
                    </div> -->
                </div>
                
         
        
            
        </div>
    </div>




<div class="modal fade" id="otp_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center align-items-center mt-3 mb-3">
                    <div class="col-auto">
                        <small class="col-form-label fw-bold" style="font-size: 18px !important;">Enter your OTP</small>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center ps-5 pe-5">
                    <div class="col-auto">
                        <p class="text-center">
                            <small class="col-form-label" style="font-size: 9pt !important;">
                                Before signing in. We need to verify your email address. We've sent you a One-Time Password (OTP) to email provided. 
                                Please enter the OTP below to proceed with signing in.
                            </small>
                        </p>
                    </div>
                </div>
                
                <form [formGroup]="otp_form" class="ps-5 pe-5">
                    <div class="row justify-content-center my-3" (paste)="pasteOtp($event)">
                        
                            <div class="col-2">
                                <input #otp1 id="otp1" (focus)="select_text('otp1')" (keyup)="handle_focus(2)" [maxlength]="1" class="text-center form-control {{otp_form.get('otp1').touched && otp_form.get('otp1').invalid? 'otp-invalid' : ''}}" formControlName="otp1"/>    
                            </div>
        
                            <div class="col-2">
                                <input #otp2 id="otp2" (focus)="select_text('otp2')" (keyup)="handle_focus(3)" [maxlength]="1" class="text-center form-control {{otp_form.get('otp2').touched && otp_form.get('otp2').invalid? 'otp-invalid' : ''}}" formControlName="otp2"/>    
                            </div>
                            <div class="col-2">
                                <input #otp3 id="otp3" (focus)="select_text('otp3')" (keyup)="handle_focus(4)" [maxlength]="1" class="text-center form-control {{otp_form.get('otp3').touched && otp_form.get('otp3').invalid? 'otp-invalid' : ''}}" formControlName="otp3"/>    
                            </div>
                            <div class="col-2">
                                <input #otp4 id="otp4" (focus)="select_text('otp4')" (keyup)="handle_focus(5)" [maxlength]="1" class="text-center form-control {{otp_form.get('otp4').touched && otp_form.get('otp4').invalid? 'otp-invalid' : ''}}" formControlName="otp4"/>    
                            </div>
                            <div class="col-2">
                                <input #otp5 id="otp5" (focus)="select_text('otp5')" (keyup)="handle_focus(6)" [maxlength]="1" class="text-center form-control {{otp_form.get('otp5').touched && otp_form.get('otp5').invalid? 'otp-invalid' : ''}}" formControlName="otp5"/>    
                            </div>
                            <div class="col-2">
                                <input #otp6 id="otp6" (focus)="select_text('otp6')" [maxlength]="1" class="text-center form-control {{otp_form.get('otp6').touched && otp_form.get('otp6').invalid? 'otp-invalid' : ''}}" formControlName="otp6"/>    
                            </div>
                        
                    </div>
                </form>

                <div class="row justify-content-center my-3">
                    <div class="col-2">
                        <label class="form-label text-danger">{{ formattedTime }}</label>
                    </div>
                    
                </div>

                <div class="row justify-content-center mb-3">
                    <div class="col-auto" *ngIf="timeLeft === 0">
                        <div class="d-grid gap-2">
                            <button class="fw-bold btn btn-outline-primary" type="button" (click)="resend_otp()">Resend OTP</button>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="d-grid gap-2">
                            <button class="btn btn-login text-white" type="button" (click)="submit_otp()">Submit OTP</button>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    </div>
</div>